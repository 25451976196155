/* eslint-disable jsx-a11y/alt-text */

// Created By Jake Rase on 8/06/2024


import React, { useEffect, useState } from 'react';
import './App.css';

let languages = {
    "Python": "py",
    "JavaScript": "js",
    "HTML": "html",
    "CSS": "css",
    "TypeScript": "ts",
    "Shell": "sh",
    "Java": "java",
    "C++": "cpp",
    "C": "c",
    "Ruby": "rb",
    "PHP": "php",
    "Go": "go",
    "Swift": "txt",
    "Objective-C": "m",
    "Kotlin": "kt",
    "Rust": "rs",
    "Scala": "scala",
    "R": "r",
    "Perl": "pl",
    null: "txt"
}

// Pre-existing project constant
const project = (title, desc, gitLink, lang, langLink, branch) => {

    return (
      <div className="project-main">
        <div className="project-innerlay">
          <div className="project-infolay">
            <h2 className="project-info-title">
              {title}
            </h2>
            <p className="project-info-subtitle">
              {desc}
            </p>
            <div className="project-git-container">
              <img src="./Images/Git.png" className="project-git-img" />
              <p className="project-git-text">
                {branch}
              </p>
            </div>
            <div className="project-info-links">
              {link(gitLink, "Github", "./Images/Github.png")}
              <div className="page-link-main">
                <i className={`bi bi-filetype-${languages[lang]}`}></i>
              </div>
              <div  onClick={() => navigator.clipboard.writeText(`${gitLink}.git`)}>
                <div className="page-link-main" >
                  <i class="bi bi-clipboard"></i>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const link = (url, text, imgaddr) => {
    return (
      <>
        <a href={url} className="page-link-main" >
          <img src={imgaddr} className="page-link-img" />
        </a>
      </>
    )
  }

// Function to fetch and render GitHub repositories
const GitHubRepos = ({ username }) => {
  const [repos, setRepos] = useState([]);

  useEffect(() => {
    const fetchRepos = async () => {
      try {
        const response = await fetch(`https://api.github.com/users/${username}/repos`);
        const data = await response.json();
        setRepos(data);
      } catch (error) {
        console.error('Error fetching the repos:', error);
      }
    };

    fetchRepos();
  }, [username]);

  return (
    <div>
      {repos.map(repo => {
        const {
          name,
          description,
          html_url,
          language,
          default_branch
        } = repo;
        
        const langLink = language ? `https://github.com/trending/${language.toLowerCase()}?since=daily` : '#';
        return (
          <div key={repo.id}>
            {project(name, description, html_url, language, langLink, default_branch)}
          </div>
        );
      })}
    </div>
  );
};

export default GitHubRepos;
